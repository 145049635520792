import React from "react"

import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

const AbstractBooklet = () => (
  <Layout>
    <Navigation />
    <iframe src="https://drive.google.com/file/d/1Hf8OzeMXJYNAowufbjSsC9tu2RMc0fcN/preview" className={"h-screen-sub w-full"} allow="autoplay"/>
    <Footer />
  </Layout>
)

export default AbstractBooklet
